import styled from "styled-components";

import {
  BP,
  Colors,
  Rem,
  Font,
} from "../../commons/Theme";

export const StyledEventbriteModal = styled.div`
  display: flex;
  color: ${Colors.black};
  background-color: ${Colors.white};
  padding-top: ${Rem(40)};
  padding-bottom: ${Rem(50)};
  flex-direction: column;

  @media (${BP.tablet}) {
    padding-top: ${Rem(120)};
    padding-bottom: ${Rem(100)};
  }
`;
export const StyledEventbriteImageBlock = styled.img`
  width: 100%;
  height: auto;
`;

export const StyledEventbriteModalRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (${BP.tablet}) {
    flex-direction: row;
  }
`;

export const StyledEventbriteModalTitleBlock = styled.div`
  flex-basis: 100%;
  padding-left: ${Rem(10)};
  padding-top: ${Rem(10)};

  @media (${BP.tablet}) {
    padding-top: ${Rem(0)};
  }
`;

export const StyledEventbriteModalTitle = styled.h4`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
  padding-bottom: ${Rem(24)};
`;

export const StyledEventbriteModalBigText = styled.h3`
  font-size: ${Rem(30)};
  line-height: ${Rem(37)};
  padding-bottom: ${Rem(24)};

  @media (${BP.ipad}) {
    font-size: ${Rem(50)};
    line-height: ${Rem(55)};
  }
`;

export const StyledEventbriteModalFormBlock = styled.div`
  padding: ${Rem(15)};
`;

export const StyledEventbriteModalButton = styled.button`
  position: relative;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${Rem(78)};
  width: 100%;
  padding: 0 ${Rem(44)};
  margin: ${Rem(10)} 0;
  font-size: ${Rem(22)};
  font-family: ${Font.sans};
  letter-spacing: 0.5px;
  white-space: nowrap;
  color: ${Colors.white};
  background-color: ${Colors.yellow};
  text-decoration: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
  z-index: 2;

  @media (${BP.ipad}) {
    font-size: ${Rem(24)};
    padding: 0 ${Rem(36)};
  }
`;
