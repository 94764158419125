import React, { useEffect } from "react";
import {
  StyledEventbriteModal,
  StyledEventbriteModalTitleBlock,
  StyledEventbriteModalTitle,
  StyledEventbriteModalBigText,
  StyledEventbriteModalRow,
  StyledEventbriteModalFormBlock,
  StyledEventbriteModalButton,
  StyledEventbriteImageBlock,
} from "./style";

export default ({
  title,
  subtitle,
  button,
  eventId,
  imageHref="https://www.3bee.com/2020/images/giuliavalentina3bee.jpg",
}) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      var exampleCallback = function () {
        console.log("Ordine completato.");
      };

      window.EBWidgets.createWidget({
        widgetType: "checkout",
        eventId: eventId,
        modal: true,
        modalTriggerElementId: "eventbrite-widget-modal-trigger-" + eventId,
        onOrderComplete: exampleCallback,
      });
    }

  }, []);

  return (
    <StyledEventbriteModal>
      <StyledEventbriteModalRow>
        <StyledEventbriteModalTitleBlock>
          <div>
            <StyledEventbriteModalBigText
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <StyledEventbriteModalTitle>
              {subtitle}
            </StyledEventbriteModalTitle>
          </div>
        </StyledEventbriteModalTitleBlock>
      </StyledEventbriteModalRow>

      <StyledEventbriteModalFormBlock>
        <StyledEventbriteModalButton type="button" id={"eventbrite-widget-modal-trigger-" + eventId}>
          {button}
        </StyledEventbriteModalButton>
      </StyledEventbriteModalFormBlock>

      <StyledEventbriteImageBlock src={imageHref} loading="lazy">
      </StyledEventbriteImageBlock>

    </StyledEventbriteModal>

  );
};
